class ApiServices {
    constructor() {
    }

    static requestOptions(methods, body, token) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        let requestOpt = {
            method: methods,
            body: body,
            headers: myHeaders,
            redirect: 'follow'
        }
        return requestOpt;
    }



}

export default ApiServices;