<template >
  <v-container style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="mt-10 d-flex justify-center">
      <v-col cols="6" style="max-width: 500px;">
        <v-card class="pa-4">
          <v-img :src="require('../../assets/logo.svg')" class="my-3 mx-auto" contain
            :style="{ 'width': isResponsive ? '60%' : '100%' }" height="200" />
          <div>
            <span class=" d-flex justify-center">
              <h1 style="color:#1e517b">אזור אישי</h1>
            </span>
          </div>
          <v-form @submit.prevent="submitHandler" ref="form">
            <v-card-text>
              <v-text-field v-model="email" label="אימייל" type="text" :rules="[v => !!v || 'שדה חובה']"
                placeholder="אימייל" required></v-text-field>
              <v-text-field v-model="password" label="סיסמה" type="password" :rules="[v => !!v || 'שדה חובה']"
                placeholder="סיסמה" required></v-text-field>
              <span @click="forgotPassword">
                <a>שכחתי סיסמה</a>
              </span>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn @click="login" color="#635ddb">
                <span class="white--text px-8">כניסה</span>
              </v-btn>
            </v-card-actions>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'

export default {

  components: {
    SnackBar
  },

  data: () => ({
    isResponsive: false,
    email: "",
    password: "",
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
  }),
  methods: {

    async login() {
      if (this.$refs.form.validate()) {

        this.progressShow = true
        let MyJSON = JSON.stringify({ email: this.email, password: this.password });
        let api = process.env.VUE_APP_BASE_URL + "/auth/login"

        try {
          const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
          this.progressShow = false;
          if (response.status === 400) {
            this.showSnackBar("שגיאת התחברות, שם משתמש או סיסמה לא תקינים", "red");
          } else if (response.status === 200) {
            const jsonObject = await response.json();
            this.showSnackBar("התחברת בהצלחה!", "green");
            localStorage.setItem('token', jsonObject.token);
            localStorage.setItem('path', jsonObject.path);
            console.log(jsonObject.path);
            window.location.pathname = jsonObject.path.substring(1);
          }
        } catch (error) {
          this.showSnackBar("Error Login: " + error, "red");
        }

      }
    },

   
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async forgotPassword() {
      if (this.email === "") {
        this.showSnackBar("יש להזין אימייל לצורך איפוס הסיסמה", "red");
        return
      }
      this.progressShow = true;
      let MyJSON = JSON.stringify({ email: this.email });
      let api = process.env.VUE_APP_BASE_URL + "/auth/forgotPassword"

      try {
        const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, ""));
        this.progressShow = false;
        if (response.status === 401) {
          this.showSnackBar("שגיאה: אימייל לא קיים", "red");
        } else if (response.status === 400) {
          this.showSnackBar("שגיאת שרת", "red");
        } else if (response.status === 200) {
          this.showSnackBar("נשלח מייל לאיפוס הסיסמה", "green");
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error Login: " + error, "red");
      }
    },
    handleResize() {
      // Check if the window width is less than or equal to 600px
      if (window.innerWidth <= 500) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
  },
  watch: {
    $route() {
      document.title = 'Login | CLICK-CALL'
    },
  },
  beforeDestroy() {
    // Remove event listener before component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    document.title = 'Login | CLICK-CALL'
    if (window.innerWidth <= 500) {
      this.isResponsive = true; // Set to true if screen size is small
    }
    window.addEventListener('resize', this.handleResize);
  },
}
</script>
<style>
@media (max-width: 500px) {
  .d-flex.justify-space-around {
    flex-direction: column;
    align-items: center;
  }

  .d-flex.justify-space-around>* {
    margin-bottom: 1rem;
  }

  .d-flex.flex-wrap {
    flex-wrap: wrap;
  }

  .d-flex.flex-wrap>* {
    flex-basis: 100%;
    margin-bottom: 10px;
    /* Add margin between lines */
  }

  h1 {
    font-size: 1.2em;
  }
}

@media (min-width: 501px) {
  h1 {
    font-size: 1.5em;
  }
}

.filtered-calls-blocked {
  font-weight: bold;
}
</style>
